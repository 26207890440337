import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducer.js";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";

const loggerMiddleware = (storeAPI) => (next) => (action) => {
  console.log("dispatching", action);
  let result = next(action);
  console.log("next state", storeAPI.getState());
  localStorage.setItem("dateFilter", JSON.stringify(storeAPI.getState()));
  return result;
};
const config = {}; // blacklist: ["filter/updateDateFilter","filter/updateMarketplace"],
const middlewares = [createStateSyncMiddleware(config)];

const middlewareEnhancer = composeWithDevTools(
  applyMiddleware(loggerMiddleware,...middlewares)
);

const store = createStore(rootReducer, middlewareEnhancer);
initStateWithPrevTab(store)

export default store;
