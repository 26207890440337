import { combineReducers } from "redux";
import accountReducer from "./reducers/accountReducer.js";
import filterReducer from "./reducers/filterReducer.js";
import { withReduxStateSync } from 'redux-state-sync'

const rootReducer = combineReducers({
  account: accountReducer,
  filter: filterReducer,
});

// export default rootReducer;
export default withReduxStateSync(rootReducer)
